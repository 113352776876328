<template>
  <section
    id="overview"
  >
    <core-jumbotron-mobile />
    <heading class="text-capitalize">
      {{ $t('terms.sales_terms') }}
    </heading>

    <v-container>
      <v-row
        align="center"
        class="mb-5"
      >
        <v-col
          class="px-3"
          cols="12"
          v-html="termsHTML"
        />
      </v-row>
    </v-container>
  </section>
</template>

<script>
  import i18n from '../i18n'

  export default {
    metaInfo: {
      title: i18n.t('menu.about_us'),
      meta: [
        { name: 'description', content: 'Oria Agriculture' },
      ],
    },
    components: {
      Heading: () => import('@/components/Heading'),

      CoreJumbotronMobile: () => import('@/components/core/JumbotronMobile'),
    },
    data: () => ({

      loading: false,
      termsHTML: null,
      error: null,

    }),
    watch: {
      // call again the method if the route changes
      $route: 'fetchData',
    },
    created () {
      // fetch the data when the view is created and the data is
      // already being observed
      this.fetchData()
    },
    methods: {

      fetchData () {
        this.error = this.post = null
        this.loading = true

        fetch('/static/terms/' + i18n.locale + '.html')
          .then(r => r.text())
          .then(html => {
                  this.loading = false
                  this.termsHTML = html
                },
                response => {
                  console.log('Error loading html:', response)
                })
      },
    },
  }
</script>
